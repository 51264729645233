export default [
    {
        id: 'companyName',
        name: 'companyName',
        title: null,
        maxLength: 256,
        label: 'nombreCompania',
        optional: false,
        type: 'text',
    },
    {
        id: 'yourName',
        name: 'name',
        title: null,
        maxLength: 256,
        label: 'nombreTu',
        optional: false,
        type: 'text',
    },
    {
        id: "email",
        name: "email",
        title: null,
        label: 'email',
        optional: false,
        type: 'email',
    },
    {
        id: "phoneNumber",
        name: "phoneNumber",
        title: null,
        label: 'numeroTelefono',
        optional: false,
        type: 'text',
    },
    {
        id: "leaveYourMessage",
        name: "message",
        maxLength: 10000,
        label: 'dejaMessage',
        optional: false,
        type: 'textarea',
    },

]
