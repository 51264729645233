export default ({ app }) => {
  app.router.afterEach((to, from) => {
    (() => {
      if(process.client) {
        const scriptContent = `
          var _comscore = _comscore || [];
          _comscore.push({
            c1: "2",
            c2: "39131668",
            options: {
              enableFirstPartyCookie: true,
              bypassUserConsentRequirementFor1PCookie: true
            }
          });
          
          (function () {
            var s = document.createElement("script"),
                el = document.getElementsByTagName("script")[0];
            s.async = true;
            s.src = 'https://sb.scorecardresearch.com/cs/391316685/beacon.js';
            el.parentNode.insertBefore(s, el);
          })();
        `;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.charset = 'utf-8';
        script.innerHTML = scriptContent;

        document.head.appendChild(script);
      }
      })();
  })
};