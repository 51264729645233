import { render, staticRenderFns } from "./default.vue?vue&type=template&id=52156868&"
var script = {}
import style0 from "./default.vue?vue&type=style&index=0&id=52156868&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/var/www/app/components/TheHeader.vue').default,TheFooter: require('/var/www/app/components/TheFooter.vue').default})
