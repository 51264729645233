export default [
    {
        id: 'companyName',
        name: 'companyName',
        title: null,
        maxLength: 256,
        label: 'Company Name',
        optional: true,
        type: 'text',
    },
    {
        id: 'yourName',
        name: 'name',
        title: null,
        maxLength: 256,
        label: 'Your Name',
        optional: true,
        type: 'text',
    },
    {
        id: "email",
        name: "email",
        title: null,
        label: 'Email',
        optional: true,
        type: 'email',
    },
    {
        id: "phoneNumber",
        name: "phoneNumber",
        title: null,
        label: 'Phone Number',
        optional: true,
        type: 'phone',
    },
    {
        id: "leaveYourMessage",
        name: "message",
        maxLength: 10000,
        label: 'Leave your message',
        optional: true,
        type: 'textarea',
    },

]
