export default {
  believe: {
    alt: "Believe",
    title: "Believe Music - Music recording company ",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd1de9656b227e0d0ad35b.png",
    sizes: "(max-width: 479px) 25vw, (max-width: 1279px) 24vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64e0a55ddc787deff103df69.png 500w, 
            https://images.projectcarmen.com/resource/ads/64dd1e13656b227e0d0ad35d.png 800w, 
            https://images.projectcarmen.com/resource/ads/64dd1e049af26df91a0e0259.png 1080w, 
            https://images.projectcarmen.com/resource/ads/64dd1df32659d4bc2b00cac3.png 1600w, 
            https://images.projectcarmen.com/resource/ads/64dd1de9656b227e0d0ad35b.png 1711w`
  },
  cinq: {
    alt: 'Cinq',
    title: "Cinq Music Group",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd1ea02659d4bc2b00cac5.png",
    sizes: "(max-width: 1439px) 18vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd1ec22659d4bc2b00cac7.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd1eb7656b227e0d0ad35f.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd1ead9af26df91a0e025b.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd1ea02659d4bc2b00cac5.png 1436w`
  },
  dashgo: {
    alt: 'DashGo',
    title: "DashGo Music Company",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd1f0c2659d4bc2b00cac9.png",
    sizes: "(max-width: 1439px) 18vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd1f332659d4bc2b00cacb.png 500w
             https://images.projectcarmen.com/resource/ads/64dd1f28656b227e0d0ad361.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd1f189af26df91a0e025d.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd1f0c2659d4bc2b00cac9.png 1436w`
  },
  empire: {
    alt: 'Empire',
    title: "EMPIRE - an American distribution company and record label ",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd1faf656b227e0d0ad363.png",
    sizes: "(max-width: 1439px) 18vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd1fd59af26df91a0e025f.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd1fca2659d4bc2b00cacd.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd1faf656b227e0d0ad363.png 963w`
  },
  ingrooves: {
    alt: 'Ingrooves',
    title: "Ingrooves Music Group",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd2035656b227e0d0ad365.png",
    sizes: "(max-width: 1439px) 18vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd206d656b227e0d0ad369.png 500w, 
            https://images.projectcarmen.com/resource/ads/64dd20632659d4bc2b00cad1.png 800w, 
            https://images.projectcarmen.com/resource/ads/64dd2058656b227e0d0ad367.png 1080w, 
            https://images.projectcarmen.com/resource/ads/64dd204e9af26df91a0e0261.png 1600w, 
            https://images.projectcarmen.com/resource/ads/64dd20412659d4bc2b00cacf.png 2000w,
            https://images.projectcarmen.com/resource/ads/64dd2035656b227e0d0ad365.png 2173w`
  },
  merlin: {
    alt: 'Merlin',
    title: "Merlin digital rights music",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd20c39af26df91a0e0269.png",
    sizes: "(max-width: 1439px) 18vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd20f22659d4bc2b00cada.png 500w, 
             https://images.projectcarmen.com/resource/ads/64dd20e7656b227e0d0ad36e.png 800w, 
             https://images.projectcarmen.com/resource/ads/64dd20d92659d4bc2b00cad8.png 1080w, 
             https://images.projectcarmen.com/resource/ads/64dd20cf2659d4bc2b00cad6.png 1600w, 
             https://images.projectcarmen.com/resource/ads/64dd20c39af26df91a0e0269.png 1711w`
  },
  orchard: {
    alt: 'Orchard',
    title: "Orchard - music distribution company",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd2142656b227e0d0ad373.png",
    sizes: "(max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd2166656b227e0d0ad377.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd215c2659d4bc2b00cadf.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd2153656b227e0d0ad375.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd2142656b227e0d0ad373.png 1436w`
  },
  sonosuite: {
    alt: 'Sonosuite',
    title: "Sonosuite Logo",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd21ce9af26df91a0e0283.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd21f9656b227e0d0ad384.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd21ee9af26df91a0e0288.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd21e32659d4bc2b00cae7.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd21d9656b227e0d0ad382.png 1600w,
             https://images.projectcarmen.com/resource/ads/64dd21ce9af26df91a0e0283.png 1621w`
  },
  sony: {
    alt: 'Sony',
    title: "Sony Music Entertainment",
    mainImage: "https://images.projectcarmen.com/resource/ads/65dd8fe26ff06b70a70ad3b8.png",
    sizes: null,
    srcSet: null
  },
  symphonic: {
    alt: 'Symphonic',
    title: "Symphonic Distribution - digital music distribution",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd22e49af26df91a0e0297.png",
    sizes: "(max-width: 479px) 25vw, (max-width: 1279px) 24vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd23209af26df91a0e029e.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd23102659d4bc2b00cb05.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd23069af26df91a0e0299.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd22fa2659d4bc2b00cb03.png 1600w,
             https://images.projectcarmen.com/resource/ads/64dd22ef656b227e0d0ad397.png 2000w,
             https://images.projectcarmen.com/resource/ads/64dd22e49af26df91a0e0297.png 2120w`
  },
  tango: {
    alt: 'Tango',
    title: "Tango Multimedia",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd23672659d4bc2b00cb10.png",
    sizes: "(max-width: 1439px) 18vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd237d2659d4bc2b00cb12.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd2372656b227e0d0ad39f.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd23672659d4bc2b00cb10.png 963w`
  },
  universal: {
    alt: 'Universal',
    title: "Universal Music Group world's leading music company.",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd23b22659d4bc2b00cb17.png",
    sizes: "(max-width: 479px) 25vw, (max-width: 1279px) 24vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd23dd656b227e0d0ad3ac.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd23d32659d4bc2b00cb19.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd23c5656b227e0d0ad3aa.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd23b22659d4bc2b00cb17.png 1436w `
  },
  warner: {
    alt: 'Warner',
    title: "Warner Music Group",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd24022659d4bc2b00cb1e.png",
    sizes: "(max-width: 479px) 25vw, (max-width: 1279px) 24vw, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd24212659d4bc2b00cb23.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd2417656b227e0d0ad3b1.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd240b9af26df91a0e02b2.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd24022659d4bc2b00cb1e.png 1436w`
  },
  oplaai: {
    alt: 'Oplaai',
    title: "Oplaai Music - Independent Record Label",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd24609af26df91a0e02b7.png",
    sizes: null,
    srcSet: null
  },
  colonize: {
    alt: 'Colonize',
    title: "Colonize Media",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd24ca9af26df91a0e02c2.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd24de2659d4bc2b00cb2b.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd24d4656b227e0d0ad3bb.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd24ca9af26df91a0e02c2.png 963w`
  },
  aquarius: {
    alt: 'Aquarius',
    title: "Aquarius - Indonesian record company",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd25309af26df91a0e02ca.png",
    sizes: "171px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd253d2659d4bc2b00cb30.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd25309af26df91a0e02ca.png 700w`
  },
  trinity: {
    alt: 'Trinity',
    title: "TRINITY OPTIMA PRODUCTION",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd258e2659d4bc2b00cb35.png",
    sizes: "171px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd25b59af26df91a0e02d2.png,
             https://images.projectcarmen.com/resource/ads/64dd25ad656b227e0d0ad3cb.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd259b656b227e0d0ad3c9.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd258e2659d4bc2b00cb35.png 1192w`
  },
  drm: {
    alt: 'DRM',
    title: "DRM - Indonesia’s 1st YouTube certified Multi-Channel Network",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd25f82659d4bc2b00cb40.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd2616656b227e0d0ad3d1.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd260c656b227e0d0ad3cf.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd2602656b227e0d0ad3cd.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd25f82659d4bc2b00cb40.png 1714w`
  },
  gnp: {
    alt: 'GNP',
    title: "Record company in Jakarta, Indonesia",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd26552659d4bc2b00cb42.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd26772659d4bc2b00cb44.png 500w,
             https://images.projectcarmen.com/resource/ads/64dd26699af26df91a0e02e3.png 800w,
             https://images.projectcarmen.com/resource/ads/64dd265f656b227e0d0ad3d3.png 1080w,
             https://images.projectcarmen.com/resource/ads/64dd26552659d4bc2b00cb42.png 1424w`
  },
  jkr: {
    alt: 'JKR',
    title: "JK Records Indonesia",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd269b656b227e0d0ad3d5.png",
    sizes: "171px",
    srcSet: `https://images.projectcarmen.com/resource/ads/64dd26ac9af26df91a0e02e5.png 500w,
    https://images.projectcarmen.com/resource/ads/64dd269b656b227e0d0ad3d5.png 544w`
  },
  ptmusica: {
    alt: 'PT Musica',
    title: "PT Musica Studios",
    mainImage: "https://images.projectcarmen.com/resource/ads/64dd246a656b227e0d0ad3b3.png",
    sizes: "171px",
    srcSet: null
  },
  tustream: {
    alt: 'TuStream',
    title: "TuStream ",
    mainImage: "https://images.projectcarmen.com/resource/ads/6512b383d3a6aadca602d9c3.png",
    sizes: null,
    srcSet: null
  },
  amazon: {
    alt: 'Amazon',
    title: "Amazon - American multinational technology company focusing on e-commerce, cloud computing, online advertising, digital streaming, ...",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea33c843abe3cb20bc20a.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea36d843abe3cb20bc20c.png 400w,
             https://images.projectcarmen.com/resource/ads/651ea35b58b7eb402802e1cc.png 600w,
             https://images.projectcarmen.com/resource/ads/64dd2602656b227e0d0ad3cd.png 800w,`
  },
  cocaCola: {
    alt: 'Coca-Cola',
    title: "Coca-Cola: an American multinational corporation founded in 1892, best known as the producer of Coca-Cola.",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea37f58b7eb402802e1ce.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea3f1843abe3cb20bc20e.png 376w,
             https://images.projectcarmen.com/resource/ads/651ea3db958714d6e108fd2d.png 560w,
             https://images.projectcarmen.com/resource/ads/651ea37f58b7eb402802e1ce.png 750w`
  },
  pg: {
    alt: 'P&G',
    title: "P&G",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea42458b7eb402802e1d0.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea473843abe3cb20bc210.png 216w,
             https://images.projectcarmen.com/resource/ads/651ea462958714d6e108fd2f.png 326w,
             https://images.projectcarmen.com/resource/ads/651ea42458b7eb402802e1d0.png 528w`
  },
  diageo: {
    alt: 'Diageo',
    title: "Diageo",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea48a58b7eb402802e1d2.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea4dd843abe3cb20bc212.png 420w,
             https://images.projectcarmen.com/resource/ads/651ea4cf958714d6e108fd31.png 640w,
             https://images.projectcarmen.com/resource/ads/651ea48a58b7eb402802e1d2.png 860w`
  },
  walmart: {
    alt: 'Walmart',
    title: "Walmart: an American multinational retail corporation that operates a chain of hypermarkets (also called supercenters),",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea4f158b7eb402802e1d4.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea52b843abe3cb20bc214.png 450w,
             https://images.projectcarmen.com/resource/ads/651ea51f958714d6e108fd33.png 680w,
             https://images.projectcarmen.com/resource/ads/651ea4f158b7eb402802e1d4.png 906w`
  },
  kfc: {
    alt: 'KFC',
    title: "Kentucky Fried Chicken (KFC), is an American fast food restaurant chain headquartered in Louisville, Kentucky, ...",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea57758b7eb402802e1d6.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea5c9843abe3cb20bc216.png 130w,
             https://images.projectcarmen.com/resource/ads/651ea5b5958714d6e108fd35.png 212w,
             https://images.projectcarmen.com/resource/ads/651ea57758b7eb402802e1d6.png 272w`
  },
  bimbo: {
    alt: 'Bimbo',
    title: "Bimbo: American corporate arm of the Mexican multinational bakery product manufacturing company Grupo Bimbo.",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea5db58b7eb402802e1d8.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea61b843abe3cb20bc218.png 280w,
             https://images.projectcarmen.com/resource/ads/651ea606958714d6e108fd37.png 380w,
             https://images.projectcarmen.com/resource/ads/651ea5db58b7eb402802e1d8.png 524w`
  },
  volkswagen: {
    alt: 'Volkswagen',
    title: "Volkswagen: a German automobile manufacturer headquartered in Wolfsburg, Lower Saxony, Germany.",
    mainImage: "https://images.projectcarmen.com/resource/ads/651ea763958714d6e108fd39.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651ea7af958714d6e108fd3b.png 190w,
             https://images.projectcarmen.com/resource/ads/651ea7a158b7eb402802e1da.png 290w,
             https://images.projectcarmen.com/resource/ads/651ea763958714d6e108fd39.png 390w`
  },
  colgate: {
    alt: 'Colgate',
    title: "Colgate",
    mainImage: "https://images.projectcarmen.com/resource/ads/651fb72656f9bf93eb0c8704.png",
    sizes: "(max-width: 479px) 110px, (max-width: 767px) 140px, (max-width: 1919px) 250px, 244px",
    srcSet: `https://images.projectcarmen.com/resource/ads/651fb96756f9bf93eb0c8706.png 620w,
             https://images.projectcarmen.com/resource/ads/651fb954958714d6e1090534.png 930w,
             https://images.projectcarmen.com/resource/ads/651fb72656f9bf93eb0c8704.png 1270w`
  },
  vydia: {
    alt: 'Vydia',
    title: 'Vydia',
    mainImage: "https://images.projectcarmen.com/resource/ads/65e6db666ff06b70a70b08ac.png",
    sizes: null,
    srcSet: null
  }
}
