
export const socialIcons = [
    { 
      title: 'Instagram', 
      imgSrc: require('~/assets/img/png/socials/instagram.png'),
      urls: {
        en: "https://www.instagram.com/trebelmusic/",
        es: "https://www.instagram.com/trebelmx/",
        id: "https://www.instagram.com/trebelmusic/"
      }
    },
    { 
      title: 'Facebook', 
      imgSrc: require('~/assets/img/png/socials/facebook.png'),
      urls: {
        en: "https://www.facebook.com/trebelapp",
        es: "https://www.facebook.com/trebelmx/",
        id: "https://www.facebook.com/trebelapp"
      }
    },
    { 
      title: 'LinkedIn', 
      imgSrc: require('~/assets/img/png/socials/linkedin.png'),
      urls: {
        en: "https://www.linkedin.com/company/trebelmusic/",
        es: "https://www.linkedin.com/company/trebelmusic/",
        id: "https://www.linkedin.com/company/trebelmusic/"
      }
    },
    { 
      title: 'TikTok', 
      imgSrc: require('~/assets/img/png/socials/tiktok.png'),
      urls: {
        en: "https://www.tiktok.com/@trebelmusic?lang=en",
        es: "https://www.tiktok.com/@trebelmx/",
        id: "https://www.tiktok.com/@trebelmusic?lang=en"
      }
    },
    {
      title: 'Youtube', 
      imgSrc: require('~/assets/img/png/socials/youtube.png'),
      urls: {
        en: "https://www.youtube.com/channel/UCPy1kySujznHGx2wHXwUWTg",
        es: "https://www.youtube.com/c/trebelmexico",
        id: "https://www.youtube.com/channel/UCPy1kySujznHGx2wHXwUWTg"
      }
    },
    { 
      title: 'Twitter', 
      imgSrc: require('~/assets/img/png/socials/twitter.png'),
      urls: {
        en: "https://twitter.com/trebelmusic?lang=en",
        es: "https://twitter.com/trebelmx",
        id: "https://twitter.com/trebelmusic?lang=en",
      }
    }, 
  ]
