export default [
  {
    fullName: 'Josi Eshkenazi',
    image: "https://images.projectcarmen.com/resource/ads/64e89715927cbb6b7d0fdad6.png",
    aboutText: {
      en: `Josi Eshkenazi is a prominent specialist in the field of sports and entertainment marketing and advertising with a vast trajectory spanning several decades. 
      With a deep expertise in advertising sales, marketing strategy and business development, he has made a significant mark in the marketing and advertising industry in México and Latin America. 
      His commitment and passion for the world of sports are reflected in his role as CEO and Co-founder of Soccer Media Solutions, a leading company he  started with a focus on innovative media solutions for the sports industry and that has evolved to become the exclusive strategic sales partner of TREBEL in Latin America. 
      As TREBEL’s board advisor, Josi helps guide strategic decisions on monetization and brand partnerships, drawing on his extensive knowledge from the media and advertising sector.
      `,
      es: `Josi Eshkenazi es un destacado especialista en el campo del marketing y la publicidad deportiva y de entretenimiento, con una amplia trayectoria que abarca varias décadas. Con una profunda experiencia en ventas publicitarias, estrategia de marketing y desarrollo de negocios, ha dejado una marca significativa en la industria del marketing y la publicidad en México y América Latina.
      Su compromiso y pasión por el mundo del deporte se reflejan en su papel como CEO y co-fundador de Soccer Media Solutions, una destacada empresa que fundó con un enfoque en soluciones mediáticas innovadoras para la industria deportiva y que ha evolucionado para convertirse en el socio estratégico exclusivo de ventas de TREBEL en América Latina.
      Como asesor del consejo de TREBEL, Josi ayuda a guiar decisiones estratégicas sobre monetización y asociaciones de marca, aprovechando su amplio conocimiento en el sector de los medios y la publicidad.
      `
    },
  },
  {
    fullName: 'Al Teller',
    image: "https://images.projectcarmen.com/resource/ads/64e897dae8d28b0feb033d8f.png",
    aboutText: {
      en: `Al Teller is a seasoned music industry veteran with a career spanning over four decades. He took charge of United Artists Records at just 29 and went on to lead global music giants CBS Records (now Sony Music) and MCA (now UMG). Teller's legacy also includes spearheading groundbreaking digital music ventures, Atomic Pop LLC and Red Ant Entertainment, which played a pivotal role in shaping online music distribution. He notably advised on Internet-related policies as a representative on the National Information Infrastructure Advisory Council. 
      Al earned a Harvard MBA and engineering degrees from Columbia University, Teller is recognized for his dynamic leadership, encompassing expertise in management, mergers and acquisitions, startups, and marketing. 
      His current role as TREBEL’s Board Advisor showcases his enduring influence in the music and technology sectors.
      `,
      es: `Al Teller es un veterano experimentado de la industria musical con una carrera que abarca más de cuatro décadas. Tomó el cargo de United Artists Records a los 29 años y luego pasó a liderar gigantes de la música a nivel mundial como CBS Records (ahora Sony Music) y MCA (ahora UMG).
      El legado de Teller también incluye liderar emprendimientos pioneros en música digital, como Atomic Pop LLC y Red Ant Entertainment, que jugaron un papel fundamental en la configuración de la distribución de música en línea. Destaca su asesoría sobre políticas relacionadas con Internet como representante en el Consejo Asesor de Infraestructura Nacional de Información.
      Al obtuvo un MBA de Harvard y títulos de ingeniería de la Universidad de Columbia. Es reconocido por su liderazgo dinámico, que abarca su experiencia en gestión, fusiones y adquisiciones, startups y marketing. Su rol actual como Asesor del Consejo en TREBEL muestra su influencia duradera en los sectores de la música y la tecnología.
      `
    },
  },
  {
    fullName: 'Maluma',
    image: "https://images.projectcarmen.com/resource/ads/6538d8aa1e7682d5ce03903f.jpeg",
    aboutText: {
      en: `Born Juan Luis Londoño Arias in Medellín, Colombia, Maluma has swiftly risen to global stardom with his magnetic blend of reggaeton and Latin trap. With multiple Billboard Latin Music Awards and Latin Grammy nominations, he has become a defining voice for a new generation of Latin music enthusiasts. 
        Beyond the stage, Maluma's influence extends to the business realm where he has emerged as a dynamic force interested in entrepreneurial ventures. 
        His keen interest in the fusion of music and technology led him to collaborate with TREBEL, aligning with the platform's vision of democratizing music consumption. As a passionate advocate for broader access to music and its power to unite, Maluma's role as a Board Advisor with TREBEL underscores his commitment to bridging the gap between artists and fans in the digital age.
        `,
      es: `Nacido como Juan Luis Londoño Arias en Medellín, Colombia, Maluma ha ascendido rápidamente a la fama mundial con su mezcla magnética de reggaetón y Latin trap. Con múltiples premios Billboard Latinos y nominaciones al Grammy Latino, se ha convertido en una voz definitoria para una nueva generación de entusiastas de la música latina.
        Más allá del escenario, la influencia de Maluma se extiende al mundo empresarial, donde ha surgido como una fuerza dinámica interesada en aventuras empresariales.
        Su gran interés en la fusión de música y tecnología lo llevó a colaborar con TREBEL, alineándose con la visión de la plataforma de democratizar el consumo de música. Como defensor apasionado de un acceso más amplio a la música y su poder para unir, el papel de Maluma como Asesor de Junta en TREBEL subraya su compromiso para cerrar la brecha entre artistas y fans en la era digital.
        `
    },
  },
  {
    fullName: 'Enrique Narciso',
    image: "https://images.projectcarmen.com/resource/ads/6538e8e71e7682d5ce039041.png",
    aboutText: {
      en: `With more than 30 years of experience in the international financial markets, Enrique has been a Principal of multiple financial institutions in the United States and Latin América. Enrique was Managing Director for LATAM of a New York based Hedge Fund and was Partner and Board Member of a leading Venezuelan insurance company. 
        As Senior Managing Director of a LATAM focused investment bank, Enrique managed a U.S.$200,000,000 diversified portfolio, with an emphasis in emerging markets. He was also a founding partner of various technology companies, including PageCell International (Cyclelogic), a company acquired by StarMedia Network, a U.S public company. Enrique was responsible for the reorganization of said company after becoming President and CEO. 
        Since 2006 he has been engaged as Business Manager and Financial Consultant for top recording artists and sports figures, overseeing the financial issues related to their productions, business activities, and wealth management. In 2022 and 2023 he was named to Billboard’s “Top Business Managers” list. 
        As an TREBEL Board Advisor, Enrique supports the company in label negotiations and Latin América expansion plans. 
        `,
      es: `Con más de 30 años de experiencia en los mercados financieros internacionales, Enrique ha sido directivo en múltiples instituciones financieras en Estados Unidos y América Latina. Enrique fue Director General para LATAM de un Hedge Fund con sede en Nueva York y fue Socio y Miembro del Consejo de una destacada compañía de seguros venezolana.
        Como Director Ejecutivo Senior de un banco de inversión enfocado en LATAM, Enrique gestionó un portafolio diversificado de U.S.$200,000,000, con énfasis en mercados emergentes. También fue socio fundador de varias compañías tecnológicas, incluida PageCell International (Cyclelogic), una empresa adquirida por StarMedia Network, compañía pública estadounidense. Enrique fue responsable de la reorganización de dicha organización tras convertirse en Presidente y CEO.
        Desde 2006, ha trabajado como Business Manager y Consultor Financiero para destacados artistas y figuras deportivas, supervisando los asuntos financieros relacionados con sus producciones, actividades empresariales y gestión de patrimonio. En 2022 y 2023 fue nombrado en la lista de “Mejores Gerentes de Negocios” de Billboard.
        Como Asesor del Consejo de TREBEL, Enrique apoya a la empresa en negociaciones con los sellos discográficos y los planes de expansión en América Latina.
        `
    },
  },
  // {
  //   fullName: 'Julián Porras',
  //   image: "https://images.projectcarmen.com/resource/ads/64e89845927cbb6b7d0fdada.png",
  //   aboutText: {
  //     en: `Julián Porras is CEO for Latin America at Omnicom Media Group, a prominent media services division of Omnicom Group Inc.
  //     He assumed this role in 2010 and has since played a pivotal role in overseeing the media landscape in the region.
  //     Omnicom Media Group encompasses renowned full-service media networks such as OMD Worldwide, PHD Worldwide, and Hearts & Science. The group also operates several subsidiary companies, including Annalect, a data and analytics firm, as well as specialized media entities like Resolution, Accuen, Novus, Optimum Sports/Fuse, Outdoor Media Group, Content Collective, and Pathway, covering various aspects of media and advertising.
  //     `,
  //     es: `Julián Porras es el CEO para América Latina en Omnicom Media Group, una destacada división de servicios de medios de Omnicom Group Inc.
  //     Asumió este cargo en 2010 y desde entonces ha desempeñado un papel fundamental en supervisar el panorama de medios en la región. Omnicom Media Group engloba reconocidas redes de medios de servicio completo como OMD Worldwide, PHD Worldwide y Hearts & Science. El grupo también opera varias compañías subsidiarias, incluida Annalect, una firma de datos y análisis, así como entidades de medios especializadas como Resolution, Accuen, Novus, Optimum Sports/Fuse, Outdoor Media Group, Content Collective y Pathway, que abarcan diversos aspectos de los medios y la publicidad.
  //     `
  //   },
  // },
]
