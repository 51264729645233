import { render, staticRenderFns } from "./noHeader.vue?vue&type=template&id=0e33b64a&"
var script = {}
import style0 from "./noHeader.vue?vue&type=style&index=0&id=0e33b64a&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheFooter: require('/var/www/app/components/TheFooter.vue').default})
