export default [
  {
    id: 'title',
    name: 'title',
    title: 'Summary Title',
    maxLength: 256,
    body: 'Help us get an idea of what this vulnerability is about.',
    label: 'Submission title',
    optional: false,
    type: 'text',
  },
  {
    id: 'severity',
    name: 'severity',
    title: 'Technical Severity',
    body: '',
    label: 'VRT Cateogry',
    type: 'select',
    options: [
      'Server Security Misconfiguration ',
      'Server-Side Injection ',
      'Broken Authentication and Session Management',
      'Sensitive Data Exposure',
      'Cross-Site Scripting (XSS)',
      'Broken Access Control (BAC)',
      'Cross-Site Request Forgery (CSRF)',
      'Application-Level Denial-of-Service (DoS)',
      'Unvalidated Redirects and Forwards ',
      'External Behavior ',
      'Insufficient Security Configurability ',
      'Using Components with Known Vulnerabilities',
      'Insecure Data Storage',
      'Lack of Binary Hardening',
      'Insecure Data Transport',
      'Insecure OS/Firmware',
      'Broken Cryptography',
      'Privacy Concerns ',
      'Network Security Misconfiguration',
      'Mobile Security Misconfiguration',
      'Client-Side Injection ',
      'Automotive Security Misconfiguration ',
      'Indicators of Compromise',
      'Other'
    ]
  },
  {
    id: "details",
    name: "details",
    title: 'Vulnerability details',
    body: '',
    label: 'URL / Location of vulnerability (optional)',
    labelDescription: "For example: https://secure.server.com/some/path/file.php",
    optional: true,
    type: 'url',
  },
  {
    id: "desc",
    name: "desc",
    title: '',
    body: '',
    label: 'Description',
    labelDescription: "Describe the vulnerability and its impact. Provide a proof of concept or replication steps. Maximum 10,000 characters.",
    optional: false,
    type: 'textarea',
  },
  {
    id: "traceDump",
    name: "traceDump",
    maxLength: 5000,
    label: 'Trace dump / HTTP request (optional)',
    optional: true,
    type: 'textarea',
  },
  {
    id: "info",
    name: "info",
    maxLength: 10000,
    label: 'Any additional information? (optional)',
    labelDescription: "Maximum 10,000 characters.",
    optional: true,
    type: 'textarea',
  },
  {
    id: "email",
    name: "email",
    title: 'Email',
    label: 'Researcher email (optional)',
    optional: true,
    type: 'email',
  },
]