
export default [
    {
    fullName: 'Gary Mekikian',
    position: {
      en: 'Chief Executive Officer',
      es: 'Director Ejecutivo'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd0ff49af26df91a0e0219.jpeg",
    aboutText: {
      en: 'Gary Mekikian is the CEO and co-founder of TREBEL. He is a serial tech entrepreneur with over 25 years of experience as a co-founder and executive of several companies.    Prior to TREBEL, Gary co-founded and served as CEO of GATeIC, a semiconductor IP company that developed algorithms for digital signal processing. Before GateIC, Gary co-founded and served as CEO and Chairman of answerFriend (which became InQuira), a natural language processing company that was acquired by Oracle. Prior to answerFriend, Gary was on the founding team at i-Cube, a provider of electronic business transformation services, where he served as Head of Sales.  i-Cube subsequently went public and was acquired by Razorfish in 1999.    Gary earned his MS degree from Stanford University’s Graduate School of Business. He also holds a BS in Electrical Engineering from the University of Southern California’s School of Engineering.',
      es: 'Gary Mekikian es el CEO y cofundador de TREBEL. Es un emprendedor tecnológico con más de 25 años de experiencia como cofundador y ejecutivo de varias empresas.     Antes de TREBEL, Gary cofundó y fungió como CEO de GATeIC, una empresa IP de semiconductores que desarrolla algoritmos para el procesamiento de señales digitales. Antes de GateIC, Gary cofundó y se desempeñó como Director Ejecutivo y presidente de answerFriend (que posteriormente se convirtió en InQuira), una empresa de procesamiento de lenguaje natural que fue adquirida por Oracle. Antes de answerFriend, Gary formó parte del equipo fundador de i-Cube, un proveedor de servicios de transformación de negocios electrónicos, donde se desempeñó como Jefe de Ventas. Posteriormente, i-Cube salió a cotizar en bolsa y fue adquirido por Razorfish en 1999.   Gary obtuvo su maestría en la Escuela de Negocios de la Universidad de Stanford. También tiene una licenciatura en Ingeniería Eléctrica por la Escuela de Ingeniería de la Universidad del Sur de California.'
    },
    hoverData: {
      artistName: "Cesária Évora",
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd10149af26df91a0e021e.gif"
    }
  },
  {
    fullName: 'Vinnie Freda',
    position: {
      en: 'President',
      es: 'Presidente'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd107f9af26df91a0e0224.jpeg",
    aboutText: {
      en: "Vinnie Freda recently joined TREBEL as President, overseeing company operations, and music industry relations.  He is a three-decade veteran of the global music industry, having worked in nearly all aspects of the business, including record labels, major and indie distribution, publishing, and corporate operations. Vinnie worked for 25 years at Universal Music Group, rising to the level of EVP and helping it become the world's largest music company.  He then joined INgrooves, a digital music pioneer, as its Chief Operating Officer. Most recently, he spent over half a decade in the C-suite of Warner Music Group as Chief Data Officer, where he was responsible for developing and executing a comprehensive vision and strategy for data management throughout the company, championing the use of data to provide useful insight.He holds a BS degree in Chemical Engineering from Stanford University, where he was student body president, and earned an MBA from Stanford GSB.",
      es: "Vinnie Freda se unió recientemente a TREBEL como Presidente, supervisando las operaciones de la empresa y las relaciones con la industria de la música. Es un veterano de la industria de la música global, con tres décadas de experiencia, habiendo trabajado en casi todos los aspectos del negocio, incluidos sellos discográficos, distribución principal e independiente, publicaciones y operaciones corporativas.    Vinnie trabajó durante 25 años en Universal Music Group, llegando a el nivel de EVP y ayudando a convertirse en la compañía de música más grande del mundo. Luego se unió a INgrooves, un pionero de la música digital, como su Director de Operaciones. Más recientemente, pasó más de media década en el C-suite de Warner Music Group como Chief Data Officer, donde fue responsable de desarrollar y ejecutar una visión y estrategia integrales para la gestión de datos en toda la empresa.    Tiene una licenciatura en Ingeniería Química de la Universidad de Stanford, donde fue presidente del cuerpo estudiantil, y obtuvo un MBA de Stanford GSB."
    },
    hoverData: {
      artistName: "Talking Heads",
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd109f656b227e0d0ad335.gif"
    }
  },
  {
    fullName: 'Robert Vanech',
    position: {
      en: 'Chief Revenue Officer',
      es: 'Director de Ingresos'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd10ca2659d4bc2b00ca91.jpeg",
    aboutText: {
      en: "Robert (Bob) Vanech is the Chief Revenue Officer of TREBEL. He has over 25 years of experience as a co-founder, executive and board member of numerous companies in the technology, media and telecom sectors. Prior to TREBEL, Bob served as the Chairman and founding CFO of Zealot Networks, a multi-platform media distributor for content creators. At Zealot, he led the company’s $100 million fundraising effort and the acquisition of 17 digital media properties, including 4 digital music companies and 6 ad monetization companies. Before Zealot, he was the CEO and co-founder at CADFORCE, a global architecture and engineering technology services firm. Prior to CADFORCE, he served as President and Director of Eureka Broadband Corporation, a telecom infrastructure and services company he founded that sold to Broadview Networks. Bob has been recognized as a nationally-ranked professional Scrabble player. He earned his BS degree in Finance from the Carroll School of Management at Boston College, and lives in Venice, CA with his wife and children.",
      es: "Robert (Bob) Vanech es el Director de Ingresos de TREBEL. Cuenta con más de 25 años de experiencia como cofundador, ejecutivo y miembro del consejo de numerosas empresas en los sectores de tecnología, medios y telecomunicaciones.    Antes de TREBEL, Bob se desempeñó como Presidente y Director de Finanzas fundador de Zealot Networks, un distribuidor de medios multiplataforma para creadores de contenido. En Zealot, dirigió el esfuerzo de recaudación de fondos de la compañía por $100 millones de dólares, además de que consiguió la adquisición de 17 propiedades de medios digitales, incluyendo 4 compañías de música digital y 6 compañías de monetización de anuncios. Antes de Zealot, fue CEO y cofundador de CADFORCE, una firma global de servicios de arquitectura e ingeniería de tecnología. Antes de CADFORCE, se desempeñó como Presidente y Director de Eureka Broadband Corporation, una empresa de servicios e infraestructura de telecomunicaciones que fundó y vendió a Broadview Networks.    Bob ha sido reconocido como un jugador profesional de Scrabble clasificado a nivel nacional en Estados Unidos. Obtuvo su licenciatura en Finanzas de la Carroll School of Management en Boston College y vive en Venice, CA con su esposa e hijos."
    },
    hoverData: {
      artistName: "Harry Chapin",
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd11112659d4bc2b00ca96.gif"
    }
  },
  {
    fullName: 'Luis Soto',
    position: {
      en: 'General Manager of Latin America',
      es: 'Gerente General de América Latina'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd11522659d4bc2b00ca9e.jpeg",
    aboutText: {
      en: "Luis Soto is a co-founder of TREBEL and leads Latin American operations and expansion. He has over 20 years of experience in the digital media industry where he has led critical transitions and growth for Mexico’s most important media companies. Prior to TREBEL, Luis was the Vice President of Digital for Televisa, the largest Spanish-language content producer and distributor in the world. At Televisa, he was responsible for the successful re-launch of the OTT platform, Blim TV, as well as increasing the monetizable audiences on the websites and mobile apps across Televisa’s three verticals: News, Entertainment, and Sports. During his tenure, Luis grew Televisa’s O&O audiences by over 130% and scaled the online cross-platform video operation to reach 50% of Mexico’s internet population. Prior to Televisa, Luis led all aspects of online strategy and digital content for Grupo Reforma, one of Mexico’s most influential media companies. Luis is an avid kiteboarder and snowboarder. He holds an MBA from Stanford University and a MS from Columbia University’s School of International and Public Affairs.",
      es: "Luis Soto es cofundador de TREBEL y lidera las operaciones y la expansión en América Latina. Tiene más de 20 años de experiencia en la industria de medios digitales, donde ha liderado transiciones críticas y el crecimiento para las empresas de medios más importantes de México.    Antes de TREBEL, Luis fue Vice Presidente de Televisa Digital, el productor y distribuidor de contenido en español más grande del mundo. En Televisa, fue responsable del exitoso relanzamiento de la plataforma OTT, Blim TV, así como del aumento de las audiencias monetizables en los sitios web y aplicaciones móviles en las tres verticales de Televisa: Noticias, Entretenimiento y Deportes. Durante su mandato, Luis aumentó las audiencias de O&O de Televisa en más del 130% y escaló la operación de video multiplataforma en línea para llegar al 50% de la población de Internet de México. Antes de Televisa, Luis dirigió todos los aspectos de la estrategia en línea y el contenido digital para Grupo Reforma, una de las empresas de medios más influyentes de México.    Luis es un ávido kiteboarder y snowboarder. Tiene un MBA por la Universidad de Stanford y una maestría de la Escuela de Asuntos Públicos e Internacionales de la Universidad de Columbia."
    },
    hoverData: {
      artistName: "Future Islands",
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd11649af26df91a0e0235.gif"
    }
  },
  {
    fullName: 'Kevin Mills',
    position: {
      en: 'Head of Business Development',
      es: 'Líder de Desarrollo de Negocios'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd11d7656b227e0d0ad33d.jpeg",
    aboutText: {
      en: "Kevin Mills is the Head of Business Development for TREBEL and is responsible for executing and managing partnerships in the Americas for the company with a focus on Mexico. He is actively involved in building TREBEL’s partnership with Televisa and executing campaigns for brands and agencies across the region. Prior to joining TREBEL, Kevin was the Managing Director of Strategy and Operations at Televisa where he was responsible for restructuring within the Content Division. He previously served as the Vice President of Digital Strategy, among other positions, at Univision Communications. Kevin, an avid sports fan, enjoys golfing with his 3-year old son. He holds a Bachelor’s degree in Communications and Journalism from Universidad de La Sabana in Bogota, Colombia and is a graduate of the Advanced Management Program in Media and Entertainment from IESE Business School in New York and Barcelona.",
      es: "Kevin Mills es el Jefe de Desarrollo de Negocios de TREBEL y es responsable de ejecutar y administrar asociaciones para la empresa en América Latina, con un enfoque en México. Participa activamente en la construcción de la asociación de TREBEL con Televisa y en la ejecución de campañas para marcas y agencias en toda la región.    Antes de unirse a TREBEL, Kevin fue el Director Gerente de Estrategia y Operaciones en Televisa, donde fue responsable de la reestructuración dentro de la División de Contenido. Anteriormente se desempeñó como Vice Presidente de Estrategia Digital, entre otros cargos, en Univision Communications.    Kevin, un ávido fanático de los deportes, disfruta jugar al golf con su hijo de 3 años. Es Licenciado en Comunicación y Periodismo por la Universidad de La Sabana en Bogotá, Colombia y es egresado del Programa de Gestión Avanzada en Medios y Entretenimiento de IESE Business School en Nueva York y Barcelona."
    },
    hoverData: {
      artistName: "Snoop Dogg",
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd11e79af26df91a0e023c.gif"
    }
  },
  {
    fullName: 'Aram Mesrobyan',
    position: {
      en: 'Chief Financial Officer',
      es: 'Director Financiero'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd12069af26df91a0e023f.jpeg",
    aboutText: {
      en: "Aram is the Chief Financial Officer at TREBEL. He joined TREBEL in September 2018. Prior to TREBEL, Aram spent eight years at Oversee.net where he was a VP/General Manager responsible for the Consumer Finance business unit, as well as Corporate Finance & Development. He started his career at Wachovia/Wells Fargo Bank as a financial analyst. Aram holds a BA in Economics from UCLA and is a CFA Charterholder.",
      es: "Aram es el Director de Finanzas de TREBEL. Se incorporó a TREBEL en septiembre de 2018.    Antes de TREBEL, Aram pasó ocho años en Oversee.net, donde fue VP y el Gerente General responsable de la unidad de negocios de Finanzas al Consumidor, así como de Finanzas Corporativas y Desarrollo. Comenzó su carrera en Wachovia / Wells Fargo Bank como analista financiero.    Aram tiene una licenciatura en Economía de UCLA y cuenta con la certificación CFA."
    },
    hoverData: {
      artistName: "The Weeknd",
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd12222659d4bc2b00caa9.gif"
    }
  },
  {
    fullName: 'Tigran Petrosyan',
    position: {
      en: 'Chief Technology Officer',
      es: 'Director de Tecnología'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd1239656b227e0d0ad33f.jpeg",
    aboutText: {
      en:'Tigran is the Chief Technology Officer at TREBEL, responsible for managing the technological roadmap and the engineering team to enhance frontend products and backend services. Prior to TREBEL, Tigran co-founded a game development company, was an R&D engineer at Synopsys, and worked in machine automation designing the CNC architecture for milling and lathing machines using National Instruments hardware. Tigran was born and raised in Yerevan, Armenia. He holds MS and BS degrees in Physics and Computer Science.',
      es: "Tigran es el Director de Tecnología de TREBEL, donde es responsable de administrar la hoja de ruta tecnológica y al equipo de ingeniería para mejorar los productos “front end” y los servicios “back end”.    Antes de TREBEL, Tigran cofundó una empresa de desarrollo de juegos, fue ingeniero de I + D en Synopsys y trabajó en la automatización de máquinas diseñando la arquitectura CNC para máquinas de torneado que utilizan hardware de National Instruments.    Tigran nació y creció en Yerevan, Armenia. Tiene una maestría y una licenciatura en Física y Ciencias Computacionales."
    },
    hoverData: {
      artistName: 'David Guetta',
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd12479af26df91a0e0241.gif"
    }
  },
  {
    fullName: 'Velia Murillo',
    position: {
      en: 'Head of People / Human Resources',
      es: 'Líder de Recursos Humanos'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd12552659d4bc2b00caae.jpeg",
    aboutText: {
      en: 'Velia Murillo joined TREBEL Music in 2022 as its first hire in our people operations. As TREBEL’s Head of People/Human Resources, Velia is responsible for building a global department from the ground up to consistently meet the needs of our growing organization and its people at an international level. Her prior experience includes leadership roles serving as Vice President of Compliance (Marketing) and Human Resources, and as Director of Customer and Product Experience. In each role respectively, she was responsible for creating a department from scratch, shaping HR, and supporting international teams for two leading companies in the online lead generation space where she spent 15 years of her career. Velia comes to TREBEL with a wealth of knowledge in Regulatory Compliance (Marketing), Customer Service, Training and Development, Recruitment, Talent Acquisition, and Employee Relations. Velia believes people are the driving force to reach and exceed business goals. She is committed to leading from the heart, supporting the needs of our global business, while cultivating a culture our TREBEL employees thrive in and are proud to be a part of.',
      es: "Velia Murillo se unió a TREBEL Music en 2022 como su primera contratación en nuestras operaciones de personas. Como Jefa de Recursos Humanos de TREBEL, Velia es responsable de construir un departamento global desde cero para satisfacer constantemente las necesidades de nuestra organización en crecimiento y su gente a nivel internacional.    Su experiencia previa incluye roles de liderazgo como Vice Presidenta de Cumplimiento (Marketing) y Recursos Humanos, y como Directora de Producto y Experiencia del Cliente. En cada función, respectivamente, fue responsable de construir un departamento desde cero, dar forma a Recursos Humanos y apoyar a equipos internacionales para dos empresas líderes en el espacio de generación de oportunidades en línea, donde pasó 15 años de su carrera. Velia llega a TREBEL con un gran conocimiento en Cumplimiento Normativo (Marketing), Servicio al Cliente, Capacitación y Desarrollo, Reclutamiento, Adquisición de Talento y Relaciones con los Empleados.    Velia cree que las personas son la fuerza impulsora para lograr y superar los objetivos comerciales. Está comprometida a liderar desde el corazón, apoyando las necesidades de nuestro negocio global, mientras cultiva una cultura en la que nuestros empleados de TREBEL prosperan y de la que se enorgullecen de ser parte."
    },
    hoverData: {
      artistName: 'Becky G',
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd12fe9af26df91a0e024a.gif"
    }
  },
  {
    fullName: 'Toto Widjojo',
    position: {
      en: 'Head of Indonesia',
      es: 'Líder de Indonesia'
    },
    image: "https://images.projectcarmen.com/resource/ads/64dd1359656b227e0d0ad351.png",
    aboutText: {
      en: "Toto joined TREBEL in 2022 as Country Head of Indonesia, and its first hire in Indonesia. He was tasked with and has successfully accomplished the official launch of our TREBEL Music app for Indonesia. Toto’s responsibilities include establishing the operations and growing a team for our Indonesian company. He is a music veteran with experience spanning three decades. During this time, Toto successfully ran Finance, Operations, and led as Country Head for Sony Music and Warner Music Indonesia. In his tenure at Sony Music, Toto’s extensive background helped grow the company's profitability and market share all while maintaining Sony Music's position as the #1 Music Company in Indonesia. Toto’s passion for gadgets and digital technology enabled him to achieve a successful launch of Sony Music’s app with limited resources. Toto earned a bachelor's degree in accountancy and attended an executive development program at NUS Stanford in Singapore.",
      es: "Toto se unió a TREBEL en 2022 como Líder de Indonesia y su primera contratación en Indonesia. Se le encomendó y logró con éxito el lanzamiento oficial de nuestra aplicación TREBEL Music para Indonesia. Las responsabilidades de Toto incluyen establecer las operaciones y hacer crecer un equipo para nuestra empresa de Indonesia.    Es un veterano de la música con una experiencia de tres décadas. Durante este tiempo, Toto dirigió con éxito Finanzas, Operaciones y lideró como Country Head para Sony Music y Warner Music Indonesia. Durante su mandato en Sony Music, la amplia experiencia de Toto ayudó a aumentar la rentabilidad y la cuota de mercado de la empresa, al mismo tiempo que mantuvo la posición de Sony Music como la empresa musical  #1 en Indonesia. La pasión de Toto por los dispositivos y la tecnología digital le permitió lograr un lanzamiento exitoso de la aplicación de Sony Music con recursos limitados.     Toto obtuvo una licenciatura en contabilidad y asistió a un programa de desarrollo ejecutivo en NUS Stanford en Singapur."
    },
    hoverData: {
      artistName: "Elvis Presley",
      artistGif: "https://images.projectcarmen.com/resource/ads/64dd136e2659d4bc2b00cab8.gif"
    }
  }
]

