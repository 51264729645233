const countries = {
    us: {
        name: 'US',
        displayName: 'United States',
        flag: 'en.png',
        iso2: 'en',
        dialCode: '1',
        length: 12,
    },
    mx: {
        name: 'MX',
        displayName: 'Mexico',
        flag: 'mx.png',
        iso2: 'mx',
        dialCode: '52',
        length: 13,
    },
    id: {
        name: 'ID',
        displayName: 'Indonesia',
        flag: 'id.png',
        iso2: 'id',
        dialCode: '62',
        length: 13,
    }
}

export default countries
