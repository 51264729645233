
import footerContent from '../../constants/footerContent';
import BaseLanguageSwitcher from '../base/BaseLanguageSwitcher.vue';
export default {
  components: {
    BaseLanguageSwitcher
  },
  computed: {
    content() {
      return footerContent[this.$i18n.locale]
    },
    homeLink() {
      return this.$i18n.locale === 'id'
        ? '/id/home'
        : this.$i18n.locale === 'es'
          ? '/es/inicio'
          : '/'
    }
  },
  data() {
    return {
      footerLogo: require('~/assets/img/png/trebel_footer_logo.png'),
    }
  }
}
