import locale77427e78 from '../../languages/en.js'
import locale7744c453 from '../../languages/es.js'
import locale777650c6 from '../../languages/id.js'
import locale771a0971 from '../../languages/br.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en.js","iso":"en-US"},{"code":"es","file":"es.js","iso":"es-ES"},{"code":"id","file":"id.js","iso":"id-ID"},{"code":"br","file":"br.js","iso":"br-BR"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "languages",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {"/":{"en":"/","es":"/es/inicio","id":"/id/home"},"about-us":{"en":"/about-us","es":"/es/sobre-nosotros"},"careers":{"en":"/careers","es":"/es/empleos"},"partners":{"en":"/partners","es":"/es/business"},"features":{"id":"/id/features"},"download":{"en":"/download","es":"/es/descargar"},"privacy-policy":{"en":"/privacy-policy","es":"/politica-de-privacidad","id":"/privacy-policy","br":"/privacy"},"terms-of-service":{"en":"/terms-of-service","es":"/terminos","id":"/terms-of-service","br":"/terms"},"bug-program":{"en":"/bug-program","es":"/bug-program","id":"/bug-program"}},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  switchLocalePath: "/:lang/:route",
  normalizedLocales: [{"code":"en","file":"en.js","iso":"en-US"},{"code":"es","file":"es.js","iso":"es-ES"},{"code":"id","file":"id.js","iso":"id-ID"},{"code":"br","file":"br.js","iso":"br-BR"}],
  localeCodes: ["en","es","id","br"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "u",
  18: "a",
  19: "g",
  20: "e",
  21: "s",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "j",
  27: "s",
  28: "\"",
  29: ",",
  30: "\"",
  31: "e",
  32: "s",
  33: ".",
  34: "j",
  35: "s",
  36: "\"",
  37: ":",
  38: "\"",
  39: ".",
  40: ".",
  41: "/",
  42: "l",
  43: "a",
  44: "n",
  45: "g",
  46: "u",
  47: "a",
  48: "g",
  49: "e",
  50: "s",
  51: "/",
  52: "e",
  53: "s",
  54: ".",
  55: "j",
  56: "s",
  57: "\"",
  58: ",",
  59: "\"",
  60: "i",
  61: "d",
  62: ".",
  63: "j",
  64: "s",
  65: "\"",
  66: ":",
  67: "\"",
  68: ".",
  69: ".",
  70: "/",
  71: "l",
  72: "a",
  73: "n",
  74: "g",
  75: "u",
  76: "a",
  77: "g",
  78: "e",
  79: "s",
  80: "/",
  81: "i",
  82: "d",
  83: ".",
  84: "j",
  85: "s",
  86: "\"",
  87: ",",
  88: "\"",
  89: "b",
  90: "r",
  91: ".",
  92: "j",
  93: "s",
  94: "\"",
  95: ":",
  96: "\"",
  97: ".",
  98: ".",
  99: "/",
  100: "l",
  101: "a",
  102: "n",
  103: "g",
  104: "u",
  105: "a",
  106: "g",
  107: "e",
  108: "s",
  109: "/",
  110: "b",
  111: "r",
  112: ".",
  113: "j",
  114: "s",
  115: "\"",
  116: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'es.js': () => Promise.resolve(locale7744c453),
  'id.js': () => Promise.resolve(locale777650c6),
  'br.js': () => Promise.resolve(locale771a0971),
}
