
import BaseStoreIcons from '../base/BaseStoreIcons.vue';
import { socialIcons } from '~/constants/footerIcons.js'
export default {
  components: {
    BaseStoreIcons
  },
  data() {
    return {
      locale: this.$i18n.locale,
      social_icons: socialIcons,
      imgSizes: {
        google: { width: '135', height: '40'},
        apple:  { width: '120', height: '40'},
        amazon:  { width: '120', height: '40'}
      }
    }
  },
}
