
export default {
	name: 'MobileNavigation',
	data() {
		return {
			logo: require('~/assets/img/png/trebel-mobile-navigation.png'),
		}
	},
	props: {
		links: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		openNav() {
			document.getElementById("nav").style.width = "100%";
		},
		closeNav() {
			document.getElementById("nav").style.width = "0%";
		}
	}
}
