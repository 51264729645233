export default [
  {
    fullName: 'Gary Mekikian',
    image: "https://images.projectcarmen.com/resource/ads/64dd0ff49af26df91a0e0219.jpeg",
    aboutText: {
      en: 'Gary Mekikian is the CEO and co-founder of TREBEL. He is a serial tech entrepreneur with over 25 years of experience as a co-founder and executive of several companies.    Prior to TREBEL, Gary co-founded and served as CEO of GATeIC, a semiconductor IP company that developed algorithms for digital signal processing. Before GateIC, Gary co-founded and served as CEO and Chairman of answerFriend (which became InQuira), a natural language processing company that was acquired by Oracle. Prior to answerFriend, Gary was on the founding team at i-Cube, a provider of electronic business transformation services, where he served as Head of Sales.  i-Cube subsequently went public and was acquired by Razorfish in 1999.    Gary earned his MS degree from Stanford University’s Graduate School of Business. He also holds a BS in Electrical Engineering from the University of Southern California’s School of Engineering.',
      es: 'Gary Mekikian es el CEO y cofundador de TREBEL. Es un emprendedor tecnológico con más de 25 años de experiencia como cofundador y ejecutivo de varias empresas.     Antes de TREBEL, Gary cofundó y fungió como CEO de GATeIC, una empresa IP de semiconductores que desarrolla algoritmos para el procesamiento de señales digitales. Antes de GateIC, Gary cofundó y se desempeñó como Director Ejecutivo y presidente de answerFriend (que posteriormente se convirtió en InQuira), una empresa de procesamiento de lenguaje natural que fue adquirida por Oracle. Antes de answerFriend, Gary formó parte del equipo fundador de i-Cube, un proveedor de servicios de transformación de negocios electrónicos, donde se desempeñó como Jefe de Ventas. Posteriormente, i-Cube salió a cotizar en bolsa y fue adquirido por Razorfish en 1999.   Gary obtuvo su maestría en la Escuela de Negocios de la Universidad de Stanford. También tiene una licenciatura en Ingeniería Eléctrica por la Escuela de Ingeniería de la Universidad del Sur de California.'
    },
  },
  {
    fullName: 'Adrián Sada',
    image: "https://images.milenio.com/-s0e-4265Bhe7mvNHbR1Ai7uKJM=/345x237/uploads/media/2015/05/13/adrian-sada-cueva-director-ejecutivo.jpeg",
    aboutText: {
      en: `Adrián Sada currently holds the position of Chief Executive Officer & Director at Vitro S.A.B. de C.V., North America's largest architectural and automobile glass manufacturer. Additionally, he sits on the boards of several institutions, including Grupo Financiero Banorte, Nemak, The Dallas Museum of Art (DMA), and the non-profit Organización Vida Silvestre, which is dedicated to wildlife conservation in Mexico. In 2019, he served as the president of CAINTRA, the Chamber of Industrial Transformation in Nuevo Leon. 
      Adrián completed his undergraduate studies at Instituto Tecnológico y de Estudios Superiores de Monterrey and later earned his MBA from Stanford University.
      `,
      es: `Adrián Sada ocupa actualmente el cargo de CEO y Director en Vitro S.A.B de C.V., el fabricante más grande de vidrio arquitectónico y automotriz en América del Norte. Además, forma parte de los consejos de administración de varias instituciones, incluyendo Grupo Financiero Banorte, Nemak, The Dallas Museum of Art (DMA) y la organización sin fines de lucro Vida Silvestre, dedicada a la conservación de la vida silvestre en México. En 2019, fue presidente de CAINTRA, la Cámara de Transformación Industrial en Nuevo León. 
      Adrián completó sus estudios de licenciatura en el Instituto Tecnológico y de Estudios Superiores de Monterrey y posteriormente obtuvo su MBA de la Universidad de Stanford.
      `
    },
  },
  {
    fullName: 'Matt McCooe',
    image: "https://images.projectcarmen.com/resource/ads/64e89381e8d28b0feb033d8d.jpeg",
    aboutText: {
      en: `Matt McCooe leads the venture capital funds, loans, and support programs for entrepreneurs and startups at Connecticut Innovations. 
      With over 25 years of experience in venture capital investment, sales, marketing, and product management, Matt represents CI on various boards of technology companies, investment funds, and serves as the chairman of the CT Biotechnology Investment Fund. 
      He has had successful investments in companies like Sema4, Budderfly, LogicSource, Tantalus Systems, and Greenworks Lending, all with nine-figure IPOs or acquisitions. Before joining CI, he led investments at Chart Venture Partners and was an early investor in companies like PacStar and CoolIT Systems. Additionally, he co-founded Eureka Networks and held key roles in Fortune 500 companies like Becton Dickinson and MCI.
      Matt  holds an MBA from Columbia University and a bachelor's degree from Boston University. He also serves as a technology and business reviewer for the National Science Foundation and the U.S. Department of Defense, and has been a member of the board of Greenwich Associates.
      `,
      es: `Matt McCooe lidera los fondos de capital de riesgo, préstamos y programas de apoyo para emprendedores y startups en Connecticut Innovations.
      Con más de 25 años de experiencia en inversión de capital de riesgo, ventas, marketing y gestión de productos, Matt representa a CI en varios consejos de administración de empresas tecnológicas y fondos de inversión y es el presidente del CT Biotechnology Investment Fund.
      Ha invertido en empresas como Sema4, Budderfly, LogicSource, Tantalus Systems y Greenworks Lending, todas con salidas exitosas a través de IPOs o adquisiciones de nueve cifras. Antes de unirse a CI, lideró inversiones en Chart Venture Partners y fue un inversionista temprano en empresas como PacStar y CoolIT Systems. Además, co-fundó Eureka Networks y ocupó roles clave en empresas Fortune 500 como Becton Dickinson y MCI.
      Matt tiene un MBA de la Universidad de Columbia y una licenciatura de la Universidad de Boston. También se desempeña como evaluador tecnológico y de negocios para la Fundación Nacional de Ciencias y el Departamento de Defensa de los Estados Unidos  y ha sido miembro del consejo de Greenwich Associates.
      `
    },
  },
  {
    fullName: 'Tigran Mekikian',
    image: "https://images.projectcarmen.com/resource/ads/64e893d2927cbb6b7d0fdad4.png",
    aboutText: {
      en: `Tigran Mekikian is the VP of Demand Partnerships at Media Alpha, a leading marketing technology company that connects brands with consumers when they are ready to purchase.  
      Tigran holds a BA in Political Science from the University of Southern California.
      `,
      es: `Tigran Mekikian es el Vicepresidente de Demand Partnerships en Media Alpha, una destacada empresa de tecnología de marketing que conecta a las marcas con los consumidores cuando están listos para comprar.
      Tigran tiene una licenciatura en Ciencias Políticas de la Universidad del Sur de California.
      `
    }
  },
  {
    fullName: 'Hary Tanoesoedibjo',
    image: "https://images.projectcarmen.com/resource/ads/64e894489644cea6f50d58af.jpeg",
    aboutText: {
      en: `Hary Tanoesoedibjo is the founder of the MNC Group and presently serves as the Chairman of PT MNC Investama Tbk. He has held key positions within the group's subsidiaries, including being President Director of PT Global Mediacom Tbk since 2002, PT Media Nusantara Citra Tbk since 2004, PT Rajawali Citra Televisi Indonesia (RCTI) since 2010, PT MNC Land Tbk since 2011, and PT GLD Property since 2012.
      Additionally, he is the President Commissioner of several companies, such as PT MNC Sky Vision Tbk (Indovision) since 2006, PT MNC Sekuritas since 2004, PT Global Informasi Bermutu (Global TV) since 2009, PT Media Nusantara Informasi since 2009, and PT Cipta Televisi Pendidikan Indonesia (MNCTV) since 2011. In 2022, he also became a board member of TREBEL. 
      Hary earned a Bachelor of Commerce (Honors) degree from Carleton University, Canada in 1988, and a Master of Business Administration from Ottawa University, Canada in 1989. Apart from his business positions, he engages in education by instructing postgraduate programs related to corporate finance, investment, and management strategy, and engages as a speaker in different national and international media occasions.
      `,
      es: `Hary Tanoesoedibjo es el fundador del Grupo MNC y actualmente se desempeña como Presidente de PT MNC Investama Tbk. Ha ocupado cargos clave dentro de las subsidiarias del grupo, incluyendo ser Director Presidente de PT Global Mediacom Tbk desde 2002, PT Media Nusantara Citra Tbk desde 2004, PT Rajawali Citra Televisi Indonesia (RCTI) desde 2010, PT MNC Land Tbk desde 2011 y PT GLD Property desde 2012.
      Además, es el Comisionado Presidente de varias empresas, como PT MNC Sky Vision Tbk (Indovision) desde 2006, PT MNC Sekuritas desde 2004, PT Global Informasi Bermutu (Global TV) desde 2009, PT Media Nusantara Informasi desde 2009 y PT Cipta Televisi Pendidikan Indonesia (MNCTV) desde 2011. En 2022, también se convirtió en miembro de la junta directiva de TREBEL.
      Hary obtuvo una licenciatura en Comercio (Honores) de la Universidad Carleton, Canadá, en 1988, y una Maestría en Administración de Empresas de la Universidad de Ottawa, Canadá, en 1989. Además de sus posiciones empresariales, participa en la educación al impartir programas de posgrado relacionados con finanzas corporativas, inversión y estrategia de gestión, y participa como ponente en diferentes eventos mediáticos nacionales e internacionales.
      `
    }
  }
]