
export default {
  data() {
    return {
      selectedLocale: this.$i18n.locale,
      showOptions: false,
    };
  },
  computed: {
    availableLocales() {
      let locales = this.$i18n.locales.filter((l) => l.code !== this.$i18n.locale)
      return this.$route.path.includes('/br/')
        ? locales : locales.filter((l) => l.code !== 'br')
    },
    langTitles() {
      if(this.$route.path.includes('br')){
        return {
          en: 'English',
          es: 'Spanish',
          id: 'Bahasa Indonesia',
          br: 'Português'
        }
      }
    return  {
        en: 'English',
        es: 'Spanish',
        id: 'Bahasa Indonesia',
      }
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    selectLocale(locale) {
      this.selectedLocale = locale;
      this.showOptions = false;
      this.$i18n.setLocale(locale);
    },
    getFlagImage(locale) {
      if(locale === 'br' && !this.$route.path.includes('br')) return

      return require(`@/assets/img/flags/${locale}.png`);
    }
  },
};
