
import { api } from '~/constants'
export default {
  props: {
    imageSizes: {
      type: Object,
      deafult: () => {}
    },
    showAll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      api,
      locale: this.$i18n.locale,
      adjustLink: "https://app.adjust.com/tue1xkd_7cmh9ov?campaign=TBD&adgroup=TBD&creative=TBD"
    }
  },
  computed: {
    includesLocale() {
      return this.$i18n.locale !== 'id'
    }
  },
  methods: {
      goTo(store) {
        let googleLink = this.$i18n.locale === 'es' ?
        'https://play.google.com/store/apps/details?id=com.mmm.trebelmusic&hl=es_MX' :
        'https://play.google.com/store/apps/details?id=com.mmm.trebelmusic'
        const link = store === 'google'
                ? googleLink
                : store === 'apple'
                ? 'https://apps.apple.com/id/app/trebel-descarga-m%C3%BAsica-legal/id912390687'
                : 'https://appgallery.huawei.com/#/app/C103535497'
        window.open( link, '_blank');
      },
      gotoAdjust() {
        window.open(this.adjustLink, '_blank')
      }
  }
}
