
import MobileNavigation from '~/components/navigation/MobileNavigation.vue'
export default {
    name: 'TheHeader',
    components: { MobileNavigation },
    props: {
        whiteBg: {
            type: Boolean,
            default: false
        },
        business: {
          type: Boolean,
          default: false
        },
        artists: {
          type: Boolean,
          default: false
        }
    },
    computed: {
        homeIcon() {
          return this.artists
              ? require('~/assets/img/png/trebel_artist.png')
              : this.business
              ? 'https://images.projectcarmen.com/resource/ads/65cf50ec5e6bf6531f0904dc.png'
              : !this.whiteBg
              ? 'https://images.projectcarmen.com/resource/ads/648ff8f52dee1b97260fd887.png'
              : require('~/assets/img/png/trebel-mobile-navigation.png')
        },
        computedWidth() {
          return this.artists ? '306' : this.business ? '337' : '140'
        },
        computedHeight() {
          return this.artists || this.business ? '64' : '43'
        },
        links() {
            return this.$i18n.locale === 'en' ? [
                { url: "/", title: 'Home' },
                { url: "/about-us", title: 'Our Story' },
                { url: "/careers", title: 'Careers' },
                { url: "/partners", title: 'Partners' },
                { url: "/forartists", title: 'TREBEL for Artists'}
            ] : this.$i18n.locale === 'es' ?
            [
                { url: "/es/inicio", title: 'Inicio' },
                { url: "/es/sobre-nosotros", title: 'Nuestra Historia' },
                { url: "/es/empleos", title: 'Empleos' },
                { url: "/es/business", title: 'TREBEL for Business' },
                { url: "/es/forartists", title: 'TREBEL for Artists'}
            ] : [
                { url: "/id/home", title: 'Home' },
                { url: "/id/features", title: 'TREBEL Indonesia' },
            ]
        },
        homeLink() {
            return this.$i18n.locale === 'id' ? '/id/home' : this.$i18n.locale === 'es' ? '/es/inicio' : '/'
        }
    }
}
